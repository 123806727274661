@font-face {
    font-family:'icomoon';
    src:url('/fonts/icomoon.eot?qx6qv1');
    src:url('/fonts/icomoon.eot?qx6qv1#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?qx6qv1') format('truetype'),
        url('/fonts/icomoon.woff?qx6qv1') format('woff'),
        url('/fonts/icomoon.svg?qx6qv1#icomoon') format('svg');
    font-weight:normal;
    font-style:normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family:'icomoon';
    speak:none;
    font-style:normal;
    font-weight:normal;
    font-variant:normal;
    text-transform:none;
    line-height:1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

.icon-github:before {
    content:"\f00a";
}

.icon-linkedin:before {
    content:"\e600";
}

.icon-stackoverflow:before {
    content:"\e601";
}

.icon-twitter:before {
    content:"\e602";
}

.icon-youtube:before {
    content:"\ea9d";
}

.icon-play:before {
    content:"\ea1c";
}

.icon-bubble:before {
    content:"\e96b";
}

.icon-info:before {
    content:"\ea0c";
}

.icon-user-tie:before {
    content:"\e976";
}

.icon-filter:before {
    content:"\ea5b";
}

.icon-database:before {
    content:"\e964";
}

.icon-wrench:before {
    content:"\e991";
}

.icon-code-start:before {
    content:"\ea7f";
}

.icon-code-end:before {
    content:"\ea80";
}
