@font-face {
    font-family: 'Roboto-Regular';
    //jsrc: url('webfont.eot'); /* IE9 Compat Modes */
    src:
        //url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        //url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
        //url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
        url('/fonts/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
        //url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}

html {
    font-family:'Roboto-Regular', sans-serif;
    font-size:$base-font-size-mobile;

    @include screen($sm) {
        font-size:$base-font-size-desktop;
    }
}

h1, h2, h3, h4, h5, h6, p {
    margin:0.5em 0;
}

h1 {
    display:inline;
    font-size:1em;
    line-height:1em;
}

h2 {
    font-size:1.5em;
    margin:1em 0 0.5em 0;
}

h3 {
    font-size:1.1em;
    margin:1em 0 0.5em 0;
}

h4, h5, h6, p {
    font-size:1em;
}

p {
    margin:1em 0 1em 0;
    line-height:1.6em;
}

h1, h2, h3, h4, h5, h6, p {
    a {
        text-decoration:none;

        &:hover, &:focus {
            text-decoration:underline;
        }
    }
}

pre {
    max-height:500px;
}

a {
    display:inline-block;
}

article {
    ul {
        padding-inline-start:20px;

        li {
            margin:5px 0;
            list-style-type:none;

            &:before {
                content:'\2022';
                float:left;
                margin-left:-20px;
                padding:3px 0;
                color:$background;
                font-size:2em;
                line-height:0.5em;
            }
        }
    }

    blockquote {
        margin:1em 0;
        padding:0.5em;
        border-left:0.5em solid $background;
        font-style:italic;
        font-size:1.5em;
    }
}

.center {
    text-align:center;
}

::selection {
    background-color:$background;
    color:white;
}
