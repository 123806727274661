aside.nav {
    padding:20px span(3);
    color:white;
    overflow:hidden;
    transition:all 0.3s;

    @include screen($sm) {
        float:left;
        position:fixed;
        top:0;
        min-height:600px;
        height:100%;
        padding-left:30px;
        padding-right:30px;
        transform:translateX(span(-35));
        z-index:2;
    }

    * {
        transition:all 0.3s;

        @include screen($sm) {
            opacity:1;
        }
    }

    a.home {
        display:block;
        border-bottom:3px solid white;
        text-decoration:none white;
        text-align:center;
        color:white;

        /*
            img {
                width:100px;
                border:1px solid white;
            }

            .circle {
                @include border-radius(100%);
            }
        */
    }

    .open-nav {
        display:block;
        margin-bottom:-20px;
        color:white;
        line-height:30px;
        font-size:0.8em;
        text-decoration:none;
        text-align:center;

        @include screen($sm) {
            display:none;
        }
    }

    h2 {
        margin:0;
        text-align:left;
        font-size:3em;
    }

    nav {
        overflow:hidden;

        @include screen-max($sm) {
            display:none;
        }

        @include screen($sm) {
            position:absolute;
            width:100%;
            top:50%;
            text-align:center;
            font-size:0;
            transform:translateY(-50%);
        }

        a {
            float:left;
            clear:both;
            position:relative;
            text-decoration:none;
            padding:5px 0;
            margin:2px;
            color:white;
            color:rgba(white, 0.5);
            font-size:24px;
            overflow:hidden;

            &:hover, &:focus, &.active {
                color:rgba(white, 1);
            }
        }

        i {
            padding-right:5px;
            font-size:20px;
        }
    }

    /*
        .sub-footer a {
            color:white;
            text-decoration:none;
            margin-top:10px;

            &:hover {
                text-decoration:underline;
            }
        }
    */

    footer {
        margin-top:20px;

        @include screen-max($sm) {
            display:none;
        }

        @include screen($sm) {
            position:absolute;
            left:30px;
            bottom:30px;
        }

        a {
            position:relative;
            bottom:0;
            height:42px;
            margin:0 5px;
            text-decoration:none;
            text-align:center;
            opacity:0.7;
            transition:all 0.3s;

            &:hover, &:focus {
                opacity:1;

                i {
                    top:0;
                }
            }

            &:first-child {
                margin-left:0;
            }
        }

        i {
            position:relative;
            display:inline-block;
            width:32px;
            height:32px;
            top:10px;
            color:white;
            line-height:32px;
            border-radius:5px;

            &.icon-github {
                background-color:rgb(32,31,31);
            }

            &.icon-stackoverflow {
                background-color:rgb(0,115,178);
            }

            &.icon-linkedin {
                background-color:rgb(254,122,21);
            }

            &.icon-twitter {
                background-color:rgb(73, 170, 230);
            }

            &.icon-youtube {
                background-color:rgb(230, 33, 23);
            }
        }
    }
}

.nav-toggled aside.nav {
    transform:translateX(0);

    @include screen($sm) {
        transform:translateX(-90%);
    }

    * {
        @include screen($sm) {
            opacity:0;
        }
    }

    #nav-close {
        opacity:0;
        animation:none;
    }

    nav, footer {
        display:block;
    }
}

#nav-open {
    position:fixed;
    left:span(1);
    margin-left:-25px;
    background-image:url("/media/aside.png");
    z-index:1;

    &:hover {
        margin-left:-20px;
    }
}

#nav-close {
    right:0;
    margin-right:-25px;
    background-color:$grey-lightest;
    background-image:url("/media/main.png");
    animation:1s ease 0s normal forwards 1 fadeIn;

    &:hover {
        margin-right:-20px;
    }
}

aside.right {
    position:fixed;
    right:0;
    bottom:0;
    //width:2.5%;
    width:span(1);
    height:100%;
    background-color:$background;
    background-image:url("/media/aside.png");
    animation:0.3s ease 0s normal forwards 1 fadeIn;
}

.diamond {
    display:none;
    width:40px;
    height:40px;
    border:0;
    padding:0;
    transform:rotate(45deg);

    @include screen($sm) {
        display:block;
    }

    &.toggle {
        position:absolute;
        top:50%;
        margin-top:-20px;
        transition:margin 0.3s;
    }
}
