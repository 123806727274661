.group {
    &:hover {
        .example {
            opacity:0.6;
        }
    }

    .example {
        padding:20px;
        cursor:pointer;
        color:$grey-dark;
        text-decoration:none;
        transition:all 0.3s;
        text-align:center;

        &:hover, &:focus {
            opacity:1;

            .info-title {
                text-decoration:underline;
            }
        }

        &:active {
            background-color:$grey;
            color:white;
        }

        .info-title {
        }

        .info {
            word-break:normal;
        }

        img {
            border:1px solid $border;
            margin:0;
        }

        .example-technologies {
            margin-top:20px;
        }
    }
}
