.youtube-video {
    display:block;
    position:relative;
    // https://css-tricks.com/aspect-ratio-boxes/
    // I'm normally finding background images which are 1280px by 720px so get the video placeholder to always be
    // that aspect ratio:
    padding-top:720px / 1280px * 100%;
    margin:1em 0;
    background-repeat:none;
    background-size:cover;
    background-position:50% 50%;

    &:hover, &:focus {
        &:before {
            opacity:0.5;
        }
    }

    &:before {
        position:absolute;
        content:'';
        width:100%;
        height:100%;
        left:0;
        top:0;
        background:black;
        opacity:0;
        transition:all 0.3s;
    }

    .youtube-video-play {
        position:absolute;
        left:50%;
        top:50%;
        padding:10px;
        margin-left:-26px;
        margin-top:-26px;
        font-size:32px;
        color:white;
        background-color:black;
    }

    .youtube-video-title {
        // Since there's sort of an aspect ratio hack going on with the parent, I want to make sure this element
        // will not effect the hack (by using position absolute).
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        padding:0.5em;
        color:white;
        text-decoration:none;
        font-size:0.8em;
        background-color:rgba(black, 0.7);
        word-break:keep-all;

        @include screen($xs) {
            font-size:1em;
            background-color:rgba(black, 0.3);
        }
    }
}
