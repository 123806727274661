main {
    display:flex;
    flex-direction:column;
    position:relative;
    min-height:100vh;
    padding:20px span(1);
    background-color:$grey-lightest;
    background-image:url(/media/main.png);
    transition:all 0.3s;
    animation:1s ease 0s normal forwards 1 fadeIn;;

    a:not(.post-preview) {
        word-break:break-all;
    }

    .content {
        margin-bottom:-30px;

        &:after {
            content:"";
            display:block;
            height:30px;
        }
    }

    .spacer {
        flex:1;
    }

    img, video {
        display:block;
        max-width:100%;
        margin:1em auto;
    }

    footer {
        height:30px;
        padding:5px;
        text-align:center;

        &:hover {
            span {
                display:none;
            }

            .song {
                display:block;
            }
        }

        span {
            cursor:pointer;
        }

        .song {
            display:none;
        }
    }
}
