.breadcrumbs {
    margin:0;
    text-align:center;
    margin-bottom:0.5em;
    color:$grey-dark;

    h1, span, a {
        font-size:1.5em;
        line-height:1.5em;

        @include screen($sm) {
            font-size:2em;
        }
    }

    a {
        text-decoration:none;
    }
}
