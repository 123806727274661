.post-preview-columns {
    display:flex;
    flex-direction:column;

    @include screen($xs) {
        margin:0 span(-0.5);
        flex-direction:row;
    }

    .post-preview {
        display:flex;
        flex-direction:column;
        float:left;
        width:span(36);

        @include screen($xs) {
            width:span(11);
            margin:0 span(0.5);
        }

        .meta {
            margin-top:auto;
        }
    }
}

.post-preview {
    display:block;
    color:$grey-dark;
    text-decoration:none;
    background-color:white;
    border:1px solid $border;
    padding:10px;
    margin:10px 0;
    border-radius:5px;

    &:hover, &:focus {
        h2 {
            text-decoration:underline;
        }
    }

    h2 {
        margin-top:0;
    }
}
