$base-font-size-mobile:18px;
$base-font-size-desktop:20px;

$xx:400;
$xs:768;
$sm:1024;
$md:1280;
$lg:1600;

$background:#14191E;
$orange:rgb(242,109,42);
$grey-lightest:rgb(240,240,240);
$grey-light:rgb(200,200,200);
$grey:rgb(140,140,140);
$grey-dark:rgb(70,70,70);
$grey-darkest:rgb(30,30,30);

$border:$grey-light;
