@mixin clearfix {
    content:"";
    display:block;
    clear:both;
}

@mixin screen($width) {
    @media only screen and (min-width:$width + 'px') { @content; }
}

@mixin screen-max($width) {
    @media only screen and (max-width:$width - 1 +'px') { @content; }
}

@function sqr($num) {
    @return $num * $num;
}
