.tags {
    text-align:center;
    margin:20px;
    font-size:0; // Hack to stop spacing between elements due to white space.
}

.tags-tag, .tags-title {
    font-size:$base-font-size-mobile;

    @include screen($sm) {
        font-size:$base-font-size-desktop;
    }
}

.tags-tag {
    position:relative;
    color:$grey-darkest;
    text-decoration:none;
    margin:5px;
    padding:10px;
    transition:all 0.3s;

    &:hover, &:focus, &.active {
        background-color:$grey-darkest;
        color:white;
    }
}
