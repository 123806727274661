html {
    // Not such a good idea as Chrome will follow this rule and note Firefox.
    // Let's say the screen width is 1920px, Chrome would then say that the page is 1920px, where as Firefox would
    // still say that the page was 1920px - width of scrollbar. The percentages are then calculated correctly on
    // Firefox but not Chrome... Perhaps Chrome then figures out left percentages and width percentages wrong.
    //overflow-y:overlay;
}

body {
    background-color:$background;
    background-image:url("/media/aside.png");

    &.exiting {
        main, #nav-close {
            animation:0.1s ease 0s normal forwards 1 fadeOut;
        }
    }
}

body.nav-toggled {
    @include screen($sm) {
        overflow:hidden;
        overflow:auto;
    }
}

.nav-toggled {
    main {
        @include screen($sm) {
            left:span(1);
            width:span(34);
        }
    }

    aside.nav .open-nav {
        display:none;
    }
}
